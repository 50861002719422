<!-- <div class="banner-panel" *ngIf="textBanner" >
  <div class="banner">
    <h4>{{textBanner}}</h4>
  </div>
</div> -->

<div class="d-sm-flex align-items-center justify-content-between  message-defil">
  <!-- le conteneur fenêtre -->
  <div class="marquee-rtl" *ngIf="nbreBanner>0">
    <!-- le contenu défilant -->
    <div>
        <span *ngFor="let banner of dataBanner"> <span class="info-cercle">&#8520;</span> {{banner.DESCRIPTION_BANNER}} <span class="ml" ></span></span>
    </div>

  </div>
</div>

<div class="main-content">
  <div></div>
  <div class="information">

    <div class="space"></div>
    <div>
      <div  class="logo">
        <img class="img img-fluid" style="width: 260px" src="./assets/img/logo_final.png"> <br>
       <!-- <span style=" font-size: 7em; color: #4b9d58;"> <b> <strong> SIGGI </strong> </b> </span> <br><br>-->
      </div>
      <div class="project">
        <div>
          <div> {{'HOME.PROJECT_1' | translate}}</div>
          <br>
          <div> {{'HOME.PROJECT_2' | translate}}</div>
        </div>
        <!-- div class="project-info">
          <div class="status">
            <div class="project-status">
              <div class="status-notStart item-status">
                <div class="status-value">{{notStartProject}}</div>
                <div class="status-label">{{'HOME.STATUS.NOT_STARTED' | translate}}</div>
              </div>
              <div class="status-start item-status">
                <div class="status-value">{{startedProject}}</div>
                <div class="status-label">{{'HOME.STATUS.IN_PROGRESS' | translate}}</div>
              </div>
              <div class="status-pending item-status">
                <div class="status-value">{{pendingProject}}</div>
                <div class="status-label">{{'HOME.STATUS.PENDING' | translate}}</div>
              </div>
              <div class="status-done item-status">
                <div class="status-value">{{doneProject}}</div>
                <div class="status-label">{{'HOME.STATUS.COMPLETED' | translate}}</div>
              </div>
            </div>
            <div class="graph">
              <div class="status-notStart" *ngIf="notStartProject > 0" [ngStyle]="{width: (100 * notStartProject / totalProject) + '%'}"></div>
              <div class="status-start"  *ngIf="startedProject > 0"  [ngStyle]="{width: (100 * startedProject / totalProject) + '%'}"></div>
              <div class="status-pending"  *ngIf="pendingProject > 0"  [ngStyle]="{width: (100 * pendingProject / totalProject) + '%'}"></div>
              <div class="status-done"  *ngIf="doneProject > 0"  [ngStyle]="{width: (100 * doneProject / totalProject) + '%'}"></div>
            </div>
          </div>
        </div -->
        <div class="">
          <br><br>
        </div>
      </div>
      <div class="description">
        <div> {{'HOME.DESCRIPTION_1' | translate}}</div>
        <div> {{'HOME.DESCRIPTION_2' | translate}}</div>
      </div>
    </div>
    <div class="space"></div>
  </div>
  <div></div>
  <div class="map" >
    <div class="space"></div>

    <img class="img img-fluid" src="./assets/img/map.png" alt="Senegal Map">

    <div class="space"></div>
  </div>
  <div></div>
</div>

<app-login *ngIf="key" [key] ="key"></app-login>
