import {Directive, EventEmitter, Input, Output} from '@angular/core';
import {SortColumn, SortDirection, SortEvent} from '../document/document.component';

const rotate: {[key: string]: SortDirection} = { asc: 'desc', desc: '', '': 'asc' };


@Directive({
  selector: 'th[sortable]',
  host: {
    '[class.asc]': 'direction === "asc"',
    '[class.desc]': 'direction === "desc"',
    '(click)': 'rotate()'
  }
})

export class SortableHeaderDirective {
  @Input() sortable: SortColumn = '';
  @Input() direction: SortDirection = '';
  @Output() sort = new EventEmitter<SortEvent>();

  constructor() { }


  rotate(): void {
    this.direction = rotate[this.direction];
    this.sort.emit({column: this.sortable, direction: this.direction});
  }

}
