import {LOCALE_ID, NgModule} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

import {HttpClientModule, HttpClient} from '@angular/common/http';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {DocumentComponent} from './document/document.component';
import { SortableHeaderDirective } from './directive/sortable-header.directive';
import { MapComponent } from './map/map.component';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';
import {HashLocationStrategy, LocationStrategy} from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { DatePipe } from './pipes/date/date.pipe';

import localeFr from '@angular/common/locales/fr';
import {registerLocaleData} from '@angular/common';

//ADD MATERIALS
import { MatTableModule } from '@angular/material/table';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { MatMenuModule } from "@angular/material/menu";
import { MatIconModule } from "@angular/material/icon";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatGridListModule } from "@angular/material/grid-list";
import { TableDocsFilterPipe } from './pipes/table-docs-filter/table-docs-filter.pipe';
// import { TableDocsFilterPipe } from './table-docs-filter.pipe';
// mat-grid-list mat-grid-tile

registerLocaleData(localeFr);


@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    LoginComponent,
    DocumentComponent,
    SortableHeaderDirective,
    MapComponent,
    UnauthorizedComponent,
    DatePipe,
    TableDocsFilterPipe
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    FormsModule,

    MatButtonModule,
    MatInputModule,
    MatTableModule,
    MatSelectModule,

    MatMenuModule,
    MatIconModule,
    MatAutocompleteModule,
    MatGridListModule,

    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    FontAwesomeModule,
    BrowserAnimationsModule
  ],
  providers: [
    {provide: LocationStrategy, useClass: HashLocationStrategy}
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }


export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, 'assets/i18n/');
}
