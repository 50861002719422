import { Component } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {formatDate} from "@angular/common";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'SIGGI';

  lang = 'fr';

  resetPassword = false;

  displayLoginPage = false;



  constructor(private translate: TranslateService) {
    this.translate.setDefaultLang(this.lang);
    this.changeLang((localStorage.getItem('lang') || navigator.language || 'fr').substr(0, 2));

  }

  changeLang(lang) {
    this.lang = lang;
    this.translate.use(lang);
    localStorage.setItem('lang', lang);
  }

  openLoginPage(state: boolean): void {
    this.displayLoginPage = state;
  }
}
