import { Pipe, PipeTransform } from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {formatDate} from "@angular/common";

@Pipe({
  name: 'appDate'
})
export class DatePipe implements PipeTransform {
  constructor(private translate: TranslateService) {}
  transform(value: string | number | Date, format: string, local?: string): string {
   return formatDate(value, format, local || this.translate.currentLang);
  }
}
/*
export  function formatDate(value: string | number | Date, format: string, locale: string, timezone?: string){

}*/
