<div  class="message">
  <div>
    <span>

          <fa-icon [icon]="faHome" style="margin: 0 5px 0 0"></fa-icon> Avenue Carde, Rue René Ndiaye – Dakar, Sénégal

      </span>
    <span style="margin: 0 20px">
      <a href="tel=+221338892106" target="_blank">
        <fa-icon [icon]="faPhone" style="margin: 0 0 0 5px"></fa-icon> Tél : (+221) 33 889 21 06
      </a>
      </span>
    <span>

      <fa-icon [icon]="faGlobe" style="margin: 0 5px 0 0"></fa-icon> <a href="mailto:contact.mepc@economie.gouv.sn"  target="_blank">
        contact.mepc@economie.gouv.sn
      </a> <a href="https://economie.gouv.sn" target="_blank">
        www.economie.gouv.sn
      </a> <a href="https://facebook.com/economie.sn"  target="_blank">
        www.facebook.com/economie.sn/
      </a>
    </span>
  </div>
</div>
