import { Injectable } from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {formatDate, formatNumber} from "@angular/common";

@Injectable({
  providedIn: 'root'
})
export class CustomFormatService {

  constructor(private translate: TranslateService) {

  }

  formatDate(value: string | number | Date, format: string, local?: string): string {
    return formatDate(value, format, local || this.translate.currentLang);
  }

  formatNumber(value: number, locale?: string, digitsInfo?: string): string{
    return formatNumber(value, locale|| this.translate.currentLang, digitsInfo);
  }

}
