import {Component, OnInit, Output, EventEmitter} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  private lang = 'fr';
  public nav_menu: boolean = false;

  @Output() openLoginPage = new EventEmitter<boolean>();

  constructor(private translate: TranslateService) { }

  login(): void {
    this.openLoginPage.emit(true);
  }

  ngOnInit(): void {
    this.lang = this.translate.currentLang;
  }

  changeLang(): void {
    const langs = ['en', 'fr'];
    const index = langs.indexOf(this.lang);
    this.lang = langs[index >= 0 ? (index + 1) % 2 : 0];
    this.translate.use(this.lang);
    localStorage.setItem('lang', this.lang);
  }

  shownav(){
    this.nav_menu = !this.nav_menu
  }
}
