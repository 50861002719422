import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Router} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class HttpService {
  private static ESPACE = 'DEMO';
  private readonly metaParams: any = {e_bksb: 'SIGGI'};
  private readonly token: number;
  constructor(private http: HttpClient, private router: Router) {
    this.token = (new Date()).getTime();

    //HttpService.ESPACE = HttpService.production ? 'SISEPP' : 'SIGGI';
     HttpService.ESPACE = HttpService.production ? 'SIGGI' : 'SISEPP';
    this.metaParams =  {e_bksb: HttpService.ESPACE, now: this.token};
  }
  public static get production(): boolean {
    return true;
  }
  get espace(){
    return HttpService.ESPACE;
  }
  private static get ROOT_URL(): string {
    /*'http://localhost:7070/delta_web_service/script/uglifyphp/dist/'; // */
    return this.production ? 'app/' :  'http://localhost:7070/sisepp/';
  }
  public static get SERVER_URL(): string {
    return HttpService.ROOT_URL + 'data/';
    // return 'http://localhost:7070/delta_web_service/data/';
  }

  private processGetParams(params: any, reqOpts: any): any {
    if (!reqOpts) {
      reqOpts = {
        params: new HttpParams()
      };
    }

    // Support easy query params for GET requests
    if (params) {
      reqOpts.params = new HttpParams();
      for (const k in params) {
        if (!params.hasOwnProperty(k)) {
          continue;
        }
        reqOpts.params = reqOpts.params.set(k, params[k]);
      }
    }
    for (const k in this.metaParams) {
      if (!this.metaParams.hasOwnProperty(k)) {
        continue;
      }
      reqOpts.params = reqOpts.params.set(k, this.metaParams[k]);
    }
    return reqOpts;
  }

  private processPostParams(reqOpts: any): any {
    return Object.assign(this.metaParams, reqOpts);
  }

  get urlClient(): string {
    return HttpService.ROOT_URL;
  }

  get(endpoint: string, params?: any, reqOpts?: any): Observable<any> {
    this.metaParams.token = (new Date()).getTime();
    const request = this.http.get<any>(HttpService.SERVER_URL + `${endpoint}.php` , this.processGetParams(params, reqOpts));
    request.subscribe(data => {}, error => {
      if (error.status === 401){
        this.router.navigate(['unauthorized']);
      }
    });
    return request;
  }


  getNative(url: string, params?: any, reqOpts?: any): Observable<any> {
    return this.http.get<any>(`${url}` , this.processGetParams(params, reqOpts));
  }

  post(endpoint: string, body: any, reqOpts?: any): Observable<any> {
    return this.http.post(HttpService.SERVER_URL + `${endpoint}.php` , this.processPostParams(body));
  }


  put(endpoint: string, body: any, reqOpts?: any): Observable<any> {
    return this.http.put(HttpService.SERVER_URL + `${endpoint}.php` , body, this.processPostParams(reqOpts));
  }

  delete(endpoint: string, params?: any, reqOpts?: any): Observable<any> {
    return this.http.delete(HttpService.SERVER_URL + `${endpoint}.php` ,  this.processGetParams(params, reqOpts));
  }

  uploadFile(endpoint: string, body: {file: File, key: string, name: string}, others?: {key: string, value: any}[]): Observable<any> {
    // const reqOpts = this.processPostParams({});
    const formData: FormData = new FormData();
    formData.append(body.key, body.file, body.name);
    if (others) {
      for (const other of others ) {
        formData.append(other.key, other.value);
      }
    }
    return this.post(endpoint , formData);
  }

  get resources(): string {
    return HttpService.ROOT_URL + 'resources/';
  }
}
