import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {HomeComponent} from './home/home.component';
import {LoginComponent} from './login/login.component';
import {DocumentComponent} from './document/document.component';
import {MapComponent} from './map/map.component';
import {UnauthorizedComponent} from './unauthorized/unauthorized.component';

const routes: Routes = [{
  path: 'home',
  component: HomeComponent
}, {
  path: 'reset_password/:id',
  component: HomeComponent
}, {
  path: 'login',
  component: LoginComponent,
  data: { title: 'Connexion' }
},  {
  path: 'documents',
  component: DocumentComponent,
  data: { title: 'Document' }
},  {
  path: 'map',
  component: MapComponent,
  data: { title: 'Map' }
}, {
  path: 'unauthorized',
  component: UnauthorizedComponent,
  data: { title: 'Unauthorized' }
}, { path: '',
  redirectTo: '/home',
  pathMatch: 'full'
}, { path: '**', component: HomeComponent }];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
