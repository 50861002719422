<div class="main-content">
  <div class="space"></div>
    <div class="content">
      <div class="space"></div>
      <div class="login-zone">
        <div class="top-panel space">
          <a class="close-button" (click)="closeLogin()" *ngIf="!loading">
            <svg width="55" height="55" viewBox="0 0 55 55" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M32.9897 21.9888L22.0081 32.9704" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M32.9932 32.977L22.0023 21.9839" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M37.4332 6.30273H17.5668C10.6436 6.30273 6.30322 11.2046 6.30322 18.1415V36.8598C6.30322 43.7967 10.623 48.6986 17.5668 48.6986H37.4309C44.377 48.6986 48.6991 43.7967 48.6991 36.8598V18.1415C48.6991 11.2046 44.377 6.30273 37.4332 6.30273Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg></a>
        </div>
        <div style="display: flex">
          <div class="space"></div>
          <div class="login-panel" *ngIf="isPasswordReset && !loadingGlobal">
            <div class="logo">
              <div class="space"></div>
              <!-- <img src="./assets/img/logo_login.png"  class="img img-fluid" /> -->
              <span style=" font-size: 7em; color: #4b9d58;"> <b> <strong> SIGGI </strong> </b> </span> <br><br>

              <div class="space"></div>
            </div>

            <div >
              <div style="margin-bottom: 35px; text-align: center">
                <h4>{{'CONFIRM.TITLE' | translate}} <b>{{user}}</b></h4>
              </div>
            </div>

            <div *ngIf="resetSended">
              <div style="margin-bottom: 35px; text-align: center">
                {{'CONFIRM.CONFIRM' | translate}}
              </div>
            </div>

            <div *ngIf="expire">
              <div style="margin-bottom: 35px; text-align: center">
                {{'CONFIRM.EXPIRE' | translate}}
              </div>
            </div>


            <form  (ngSubmit)="confirmReset()" [formGroup]="confirmForm" style="width: 100%" *ngIf="expire == 0 && !resetSended">
              <div class="form-group">
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text"><img src="assets/img/Unlock.png" alt="email-icon"></span>
                    <span class="input-group-text">{{'LOGIN.PASSWORD' | translate}} : </span>
                  </div>
                  <input class="form-control" formControlName="password" type="password"  />
                </div>
                <div *ngIf="password.errors" class="text-error error-message">
                  <div *ngIf="password.errors.required && !password.errors.minlength">{{  'CONFIRM.REQUIRED'   | translate}}</div>
                  <div *ngIf="password.errors.minlength">{{ 'CONFIRM.INFO_PASSWORD'  | translate}}</div>
                </div>

              </div>
              <div class="form-group">
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text"><img src="assets/img/Unlock.png" alt="email-icon"></span>
                    <span class="input-group-text">{{'CONFIRM.CONFIRM_PASSWORD' | translate}} : </span>
                  </div>
                  <input class="form-control" formControlName="confirmPassword" type="password"  />
                </div>

                <div *ngIf="password.value !== confirmPassword.value && !password.errors" class="text-error error-message">
                  {{'CONFIRM.NOT_SAME' | translate}}
                </div>
              </div>

              <div class="text-center">
                <button type="submit" [disabled]="password.errors?.minlength || password.errors?.required " class=" login-button"   *ngIf="!loading">{{'LOGIN.LOGIN' | translate}}</button>
                <div class="spinner-border text-success" role="status"  *ngIf="loading">
                  <span class="sr-only">Login...</span>
                </div>
              </div>
            </form>

          </div>

          <div class="login-panel" *ngIf="!isPasswordReset && !loadingGlobal">
            <div class="logo">
              <div class="space"></div>
              <!-- <img src="./assets/img/logo_login.png"  class="img img-fluid" /> -->
              <span style=" font-size: 7em; color: #fff;"> <b> <strong> SIGGI </strong> </b> </span> <br><br>

              <div class="space"></div>
            </div>

            <div *ngIf="resetPassword && !resetSended">
              <div style="margin-bottom: 35px">
                {{'LOGIN.RESET_MESSAGE' | translate}}
              </div>
              <form  (ngSubmit)="sendReset()" [formGroup]="resetForm" style="width: 100%">
                <div class="form-group">
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text"><img src="assets/img/Message.png" alt="email-icon"></span>
                      <span class="input-group-text">{{'LOGIN.EMAIL' | translate}} : </span>
                    </div>
                    <input class="form-control email-input" type="email" formControlName="email" id="email_reset" email="true"  />
                  </div>
                  <div *ngIf="resetEmail.errors?.email" class="text-error error-message">
                    {{'LOGIN.ERROR_EMAIL' | translate}}
                  </div>
                </div>
                <div class="text-center">
                  <button type="submit" [disabled]="resetEmail.errors?.email || resetEmail.errors?.required  " class=" login-button"   *ngIf="!loading">{{'LOGIN.RESET' | translate}}</button>
                  <div class="spinner-border text-success" role="status"  *ngIf="loading">
                    <span class="sr-only">Login...</span>
                  </div>
                </div>
              </form>
            </div>

            <div *ngIf="resetPassword && resetSended">
              <div style="margin-bottom: 35px">
                {{'LOGIN.RESET_SEND_MESSAGE' | translate}}
              </div>
            </div>

            <div class="welcome" *ngIf="!resetPassword">{{'LOGIN.WELCOME' | translate}}</div>
            <form  (ngSubmit)="login()" [formGroup]="loginForm" style="width: 100%" *ngIf="!resetPassword">
              <div class="form-group">
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text"><img src="assets/img/Message.png" alt="email-icon"></span>
                    <span class="input-group-text">{{'LOGIN.EMAIL' | translate}} : </span>
                  </div>
                  <input class="form-control email-input" type="email" formControlName="email" id="email" email="true"  />
                </div>
                <div *ngIf="email.errors?.email" class="text-error error-message">
                  {{'LOGIN.ERROR_EMAIL' | translate}}
                </div>
              </div>
              <div class="form-group">
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text"><img src="assets/img/Unlock.png" alt="email-icon"></span>
                    <span class="input-group-text">{{'LOGIN.PASSWORD' | translate}} : </span>
                  </div>
                  <input class="form-control" formControlName="password" type="password"  />
                </div>
              </div>

              <div class="form-group">
                <div *ngIf="errorLogin" class="text-error" style="font-weight: bold; text-align: center">
                  {{errorLogin.msg}}
                </div>
              </div>

              <div class="text-center forgot-password" style="margin-top: 50px; " >
                <span  >{{'LOGIN.FORGOT' | translate}}</span> <a style="cursor: pointer" (click)="forgotPassword()">{{'LOGIN.CLICK_HERE' | translate}}</a>
              </div>

              <div class="text-center">
                <button type="submit" [disabled]="email.errors?.email || email.errors?.required || password.errors?.required " class=" login-button"   *ngIf="!loading">{{'LOGIN.LOGIN' | translate}}</button>
                <div class="spinner-border text-success" role="status"  *ngIf="loading">
                  <span class="sr-only">Login...</span>
                </div>
              </div>
            </form>

          </div>


          <div class="space">

          </div>

        </div>

        <div class="space delta-licencing">
          <!--<div> {{'FOOTER.MSG' | translate}}</div>-->
        </div>
      </div>
      <div class="space"></div>
    </div>


  <div class="space"></div>

</div>
